<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        จัดการสินค้าหมดอายุ : WH5
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary me-3"
          style="font-size: 14px; font-weight: bold"
          @click="exportByCheckbox"
        >
          Export
          <i
            style="font-size: 18px; margin-bottom: 2px"
            class="bi bi-box-arrow-in-right"
          ></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มข้อมูล
        </button>
      </div>
    </div>

    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-12 mb-5" style="font-size: 18px">
        <label>ค้นหาจาก </label>
      </div>
      <div class="col-sm-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
        <!-- <datalist id="datalistOptions">
          <option
            v-for="(item, index) in allData"
            :key="index"
            :value="item.product.product_code"
          >
            {{ item.product.product_barcode }} -
            {{ item.product.product_name }} -
            {{ item.expire_no }}
          </option>
        </datalist> -->
      </div>
      <div class="col-sm-2 mb-4">
        <select v-model="searchInputByMonth" class="form-select">
          <option value="">ระบุเดือน</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>
      </div>
      <div class="col-sm-2 mb-4">
        <input
          v-model="searchInputByYear"
          maxlength="4"
          placeholder="ระบุปี ค.ศ."
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>

    <div class="card-title row ms-7 me-5 mt-6">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-1 mt-4">
            <input
              type="checkbox"
              @change="checkAll = !checkAll"
              :checked="checkAll ? true : false"
            />
          </div>
          <div class="col-2">
            <button
              @click="deleteByCheckbox()"
              class="btn btn-link btn-color-muted btn-active-color-danger"
            >
              <i class="bi bi-trash3-fill ms-1" style="font-size: 16px"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body pt-3">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>
                <!-- <input type="checkbox" @change="checkAll = !checkAll" /> -->
                All
              </th>
              <th>Item code</th>
              <th>Item name</th>
              <th>Barcode</th>
              <th>POG</th>
              <th>Expire Date</th>
              <th>Expire Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="8">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                <input
                  type="checkbox"
                  @change="isChecked(item)"
                  :checked="checkAll ? true : false"
                />
              </td>
              <td>{{ item.product.product_code }}</td>
              <td>{{ item.product.product_name }}</td>
              <td>{{ item.product.product_barcode }}</td>
              <td>{{ item.product.product_pog }}</td>
              <td>
                <span
                  :style="
                    !item.expire_date
                      ? ''
                      : item.expire_date < newDate
                      ? 'border-bottom: 3px solid Crimson'
                      : item.expire_date === newDate
                      ? 'border-bottom: 3px solid #f1416c'
                      : item.expire_date > newDate
                      ? 'border-bottom: 3px solid #3699ff'
                      : ''
                  "
                >
                  <!-- {{ item.expire_date ? item.expire_date : "-" }} -->
                  {{ formatDate(item.expire_date) }}
                </span>
              </td>
              <!-- <td>{{ item.product_wholesale_price }}</td> -->
              <td>{{ item.expire_no }}</td>

              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToPrint()"
                    >
                      <i class="bi bi-printer-fill"></i>
                    </button>
                  </div>
                  <div class="col-sm-6 d-flex">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    /> -->

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
    <SnackbarDelete
      :showSnackbar="showSnackbar"
      @closeSnackbar="closeSnackbar"
      @snackbarConfirmDelete="snackbarConfirmDelete"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

// import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import SnackbarDelete from "../../components/snackbarDelete.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

export default {
  components: {
    // DialogConfirmByPass,
    Pagination,
    SnackbarDelete,
    Loader,
  },
  setup() {
    document.title = "J&N | จัดการสินค้าหมดอายุ";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,
    // dialogConfirmByPass: false,
    newDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    showing1: null,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchInputByMonth: "",
    searchInputByYear: "",

    allData: [],
    oldSearchYearInput: 0,

    // loadingUpdate: false,
    showSnackbar: false,

    showing1: null,

    checkbox: [],
    newCheckbox: [],

    checkAll: false,
    // dialogConfirmByPass: false,
  }),
  watch: {
    checkAll(val) {
      if (val) {
        this.checkbox = this.dataItemsShow;
        this.newCheckbox = this.dataItemsShow;
      } else {
        this.checkbox = [];
        this.newCheckbox = [];
      }
    },
  },

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.product.getAllExpire();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push({
        path: "/warehouse/WH5/new",
        query: {},
      });
    },

    goToPrint() {
      // this.$router.push({
      //   name: "WH4-edit",
      //   query: {
      //     id: "123",
      //   },
      // });
    },

    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.product.product_code} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await whApi.product.deleteExp(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },

    // handleApprove() {
    //   this.dialogConfirmByPass = true;
    // },

    // submitConfirmPass() {
    //   this.isApprove = true;
    //   this.dialogConfirmByPass = false;
    // },
    // closeDialogConformByPass() {
    //   this.isApprove = false;
    //   this.dialogConfirmByPass = false;
    // },

    isChecked(val) {
      let findIndex = this.checkbox.indexOf(val);
      if (findIndex !== -1) {
        this.newCheckbox = [...this.checkbox];
        this.newCheckbox.splice(findIndex, 1);
      } else {
        this.checkbox.push(val);
        this.newCheckbox = this.checkbox;
      }
    },
    exportByCheckbox() {
      this.checkbox = this.newCheckbox;
      this.$router.push("/warehouse/WH5/new");
      // this.$router.push("/warehouse/WH5/importExcel");
    },
    deleteByCheckbox() {
      this.checkbox = this.newCheckbox;
      if (this.checkbox.length) {
        Swal.fire({
          icon: "question",
          text: `ต้องการลบ ${this.checkbox.length} รายการหรือไม่ ?`,
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.showSnackbar = true;
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          text: `กรุณาเลือกรายการที่จะลบก่อน`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    snackbarConfirmDelete() {
      this.checkbox.forEach((element, index) => {
        this.confirmDelete(element, index);
      });
    },
    closeSnackbar() {
      this.showSnackbar = false;
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");
        // const year = parseInt(oldDate.substring(6, 10)) + 543;
        // const newDate = moment(date).format(`DD/MM/${year}`);

        return oldDate;
      } else {
        return "-";
      }
    },

    async search() {
      this.loading = true;
      let newDate = "";
      if (this.searchInputByMonth && this.searchInputByYear) {
        newDate = `${this.searchInputByYear}-${
          parseInt(this.searchInputByMonth) < 10
            ? `0${this.searchInputByMonth}`
            : this.searchInputByMonth
        }`;
      }
      if (this.searchInputByYear && !this.searchInputByMonth) {
        newDate = `${this.searchInputByYear}`;
      }
      if (!this.searchInputByMonth && !this.searchInputByYear) {
        newDate = "";
      }

      const responseSearch = await whApi.product.searchExpByDate({
        search: this.searchInput,
        date: newDate,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
